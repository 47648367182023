import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import isEmpty from 'lodash.isempty'
import some from 'lodash.some'
import {
  Button,
  Col,
  Empty,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd'
import {
  createCompany,
  deleteCompany,
  getCompanies,
  updateCompany,
} from './api'
import { Can, ErrorMessage, Icon } from '../../../_shared/components'
import { routePropTypes } from '../../../_shared/types'
import { getTableHeaderSearchInput } from '../../utils'
import { CompanyModal } from './CompanyModal'

const CompaniesPage = ({ retailers }) => {
  const navigate = useNavigate()
  const { Title } = Typography
  const location = useLocation()

  const [isCompanyModalVisible, setCompanyModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedCompany, setSelectedCompany] = useState(null)
  const queryParams = queryString.parse(location.search)
  const itemsPerPage = parseInt(queryParams.items_per_page, 10) || 25
  const page = parseInt(queryParams.page, 10) || 1

  const { data, error, isLoading, reload } = useAsync({
    itemsPerPage,
    name: queryParams.name,
    page,
    promiseFn: getCompanies,
    watch: location.search,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  const handleDeleteCompany = (recordId) => {
    deleteCompany({ recordId })
      .then(() => handleSuccess({ description: 'Company is removed successfully.' }, true))
      .catch((error) => handleFailure(error, { description: 'Company removal is failed.' }))
  }

  const handleSuccess = (messageOptions = {}, isDelete = false) => {
    if (isDelete) {
      navigate('/companies?page=1')
    } else {
      reload()
    }

    setCompanyModalVisibility(false)
    setErrorMessage(null)

    notification.open({
      description: 'Company is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Company creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'CREATE':
      return createCompany({ fields: payload })
        .then(() => handleSuccess())
        .catch((error) => handleFailure(error))

    case 'UPDATE':
      return updateCompany({ fields: payload })
        .then(() => handleSuccess({ description: 'Company is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Company update is failed.' }))

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = ({ selectedCompany, setModalVisibility }) => {
    setSelectedCompany(selectedCompany)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleTableChange = (pagination, filters) => {
    const queryStringObject = {
      ...filters,
      items_per_page: pagination.pageSize,
      page: pagination.current,
    }

    navigate({
      ...location,
      search: queryString.stringify(queryStringObject),
    })
  }

  const companies = (data && data.companies) || []
  const totalItems = (data && data.pagination_meta.total_items) || 0
  const filteredRetailers = retailers.filter(retailer => !some(companies, { retailer_id: retailer.id }))

  const tableColumns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'nameSelenium',
      dataIndex: 'name',
      filteredValue: queryParams.name || null,
      key: 'name',
      title: 'Company Name',
      ...getTableHeaderSearchInput('name'),
    },
    {
      className: 'retailerSelenium',
      dataIndex: 'retailer',
      key: 'retailer',
      render: (_, record) => record.retailer?.name ,
      title: 'Retailer',
    },
    {
      className: 'addressSelenium',
      dataIndex: 'address',
      key: 'address',
      title: 'Address',
    },
    {
      className: 'descriptionSelenium',
      dataIndex: 'description',
      key: 'description',
      title: 'Description',
    },
    {
      className: 'actionSelenium',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:companies"
            yes={() => (
              <Button
                className="action-list-button"
                name="updateCompanySelenium"
                onClick={() => handleOpenModal({
                  selectedCompany: record,
                  setModalVisibility: setCompanyModalVisibility,
                })}
                size="small"
              >
                Update
              </Button>
            )}
          />
          <Can
            requiredPermission="delete:companies"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={!isEmpty(record.stores) || !isEmpty(record.owned_stores)}
                okText="Yes"
                onConfirm={() => handleDeleteCompany(record.id)}
                title="Are you sure you want to delete this company?"
              >
                <Button
                  danger
                  disabled={!isEmpty(record.stores) || !isEmpty(record.owned_stores)}
                  ghost
                  name="deleteCompanySelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: '15%',
    },
  ]

  return (
    <div>
      <Title>Companies</Title>

      <Row>
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:companies"
            yes={() => (
              <Button
                name="addNewCompanySelenium"
                onClick={() => handleOpenModal({
                  selectedCompany: null,
                  setModalVisibility: setCompanyModalVisibility,
                })}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>

        <Col span={24}>
          <Table
            bordered
            className="companyTableSelenium"
            columns={tableColumns}
            dataSource={companies}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No companies" /> }}
            onChange={handleTableChange}
            pagination={{
              current: page,
              pageSize: itemsPerPage,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              total: totalItems,
            }}
            rowKey="id"
            tableLayout="auto"
          />
        </Col>
      </Row>

      {isCompanyModalVisible && (
        <CompanyModal
          errorMessage={errorMessage}
          filteredRetailers={filteredRetailers}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedCompany={selectedCompany}
          setModalLoading={setModalLoading}
          setVisibility={setCompanyModalVisibility}
        />
      )}
    </div>
  )
}

CompaniesPage.propTypes = {
  ...routePropTypes,
  retailers: PropTypes.array,
}

export default CompaniesPage
