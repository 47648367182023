import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd'
import { isEmpty, omit } from 'lodash'
import { formPropTypes, storePropTypes } from '../../types'
import './StoreModal.css'

const { Option } = Select
const { TextArea } = Input

const CountrySelector = forwardRef(({ countryList = [], ...props }, ref) => {
  const cleanedProps = omit(props, 'countryList')

  return (
    <Select ref={ref} showSearch {...cleanedProps}>
      {countryList?.map(({ text }) =>
        <Option key={text} name="depositCountryOption" value={text}>{text}</Option>,
      )}
    </Select>
  )})

CountrySelector.propTypes = {
  countryList: PropTypes.array,
}

const changeConstant = 10.76

const CompanySelector = forwardRef(({ companyList = [], ...props }, ref) => {
  const cleanedProps = omit(props, 'companyList')

  return(
    <Select allowClear={true} ref={ref} showSearch {...cleanedProps}>
      {companyList.map(({ text, value }) =>
        <Option key={text} name="companyOption" value={value}>{text}</Option>,
      )}
    </Select>
  )})

CompanySelector.propTypes = {
  companyList: PropTypes.array,
}

const StoreForm = ({
  companyList,
  countryList,
  errorMessage,
  isModalLoading,
  onSubmit,
  selectedStore,
  setModalLoading,
  setVisibility,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched, setFieldsValue } = form
  const isUpdate = !!selectedStore

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = getFieldsValue([
            'address',
            'area',
            'city',
            'company_id',
            'country',
            'description',
            'external_id',
            'id',
            'latitude',
            'longitude',
            'name',
            'number_of_shipping_containers',
            'owner_id',
            'state',
            'zip',
          ])
          fieldsValue.company_id = fieldsValue.company_id || ''
          fieldsValue.owner_id = fieldsValue.owner_id || ''

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'
          const id = isUpdate ? selectedStore.id : undefined

          onSubmit(actionType, { ...fieldsValue, id })
        })
        .catch((error) => handleError(error))
    } else {
      setVisibility(false)
    }
  }

  const handleError = (error) => {
    console.log(error)
    return null
  }

  const handleCancel = () => {
    setVisibility(false)
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelStoreBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateStoreSelenium' : 'addStoreSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={isUpdate ? 'Update store' : 'Add a new store'}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          initialValue={isUpdate ? selectedStore.external_id : undefined}
          label="Store External Id"
          name="external_id"
        >
          <Input
            disabled={isUpdate && !isEmpty(selectedStore?.external_id)}
          />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.name : undefined}
          label="Store Name"
          name="name"
          rules={[
            {
              message: 'Please add a store name!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.country : undefined}
          label="Country"
          name="country"
          rules={[
            {
              message: 'Please select a country!',
              required: true,
            },
          ]}
        >
          <CountrySelector countryList={countryList} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.state : undefined}
          label="State"
          name="state"
          rules={[
            {
              message: 'Please add a state!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.city : undefined}
          label="City"
          name="city"
          rules={[
            {
              message: 'Please add a city!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.zip : undefined}
          label="Zip"
          name="zip"
          rules={[
            {
              message: 'Please add a zip code!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.address : undefined}
          label="Street Address"
          name="address"
          rules={[
            {
              message: 'Please add a street address!',
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.latitude : undefined}
          label="GPS lat"
          name="latitude"
          rules={[
            {
              message: 'Please add a latitude!',
              required: true,
            },
            {
              message: 'GPS latitude should contain only numbers!',
              pattern: new RegExp('^(-)?[0-9]+(.[0-9]+)?$'),
            },
          ]}
        >
          <InputNumber step={0.000001} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.longitude : undefined}
          label="GPS long"
          name="longitude"
          rules={[
            {
              message: 'Please add a longitude!',
              required: true,
            },
            {
              message: 'GPS longitude should contain only numbers!',
              pattern: new RegExp('^(-)?[0-9]+(.[0-9]+)?$'),
            },
          ]}
        >
          <InputNumber step={0.000001} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.area : undefined}
          label="Store area in sqft"
          name="area"
          onChange={({ target }) => {
            setFieldsValue({ areaInMeters: target.value === '' ? '' : target.value / changeConstant })}
          }
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate && selectedStore.area !== null ? (selectedStore.area / changeConstant) : undefined}
          label="Store area in sqm"
          name="areaInMeters"
          onChange={({ target }) => {
            setFieldsValue({ area: target.value === '' ? '' : target.value * changeConstant })}
          }
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.company_id : undefined}
          label="Company (retailer/brand)"
          name="company_id"
        >
          <CompanySelector companyList={companyList} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.owner_id : undefined}
          label="Owner (franchise/corporate)"
          name="owner_id"
        >
          <CompanySelector companyList={companyList} />
        </Form.Item>

        <Form.Item
          initialValue={isUpdate ? selectedStore.description : undefined}
          label="Comment"
          name="description"
        >
          <TextArea style={{ minHeight: 100 }} />
        </Form.Item>

      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

StoreForm.propTypes = {
  companyList: PropTypes.array,
  countryList: PropTypes.array,
  errorMessage: PropTypes.string,
  form: formPropTypes,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: storePropTypes,
  setModalLoading: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
}

export const StoreModal = StoreForm
