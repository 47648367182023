import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Col,
  Descriptions,
  Modal,
  notification,
  Popconfirm,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd'
import { Can, ErrorMessage, Icon } from '../../../_shared/components'
import { routePropTypes } from '../../../_shared/types'
import { localizedDate } from '../../utils'
import {
  cancelShippingContainer,
  createShippingContainerMedia,
  deleteShippingContainerMedia,
  getShippingContainerDetails,
  setShippingContainerStatus,
} from './api'
import PackagesTable from './PackagesTable'
import ChangelogModal from './ChangelogModal'

const { Title, Paragraph } = Typography
const { confirm } = Modal

const ShippingContainerDetailsPage = ({ barcodes }) => {
  const { id: shippingContainerId } = useParams()
  const [isChangelogModalVisible, setChangelogModalVisibility] = useState(false)
  const { data, error, isLoading, reload } = useAsync({
    promiseFn: getShippingContainerDetails,
    shippingContainerId,
  })
  const navigate = useNavigate()

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  if (isLoading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  const handleMediaUpload = (media) => {
    createShippingContainerMedia({ media, shippingContainerId })
      .then(handleSuccess)
      .catch((error) => {
        media.onError()
        handleFailure(error)
      })
  }

  const handleCancelProcessing = () => {
    cancelShippingContainer({
      action: 'cancel',
      shippingContainerId,
    })
      .then(() => handleSuccess({ description: 'Shipping container status has been changed to "canceled" successfully.' }))
      .catch((error) => handleFailure(error, { description: `Shipping container status editing has failed. ${error}` }))
  }

  const handleCompleteProcessing = () => {
    setShippingContainerStatus({
      action: 'process',
      backendTimeFieldName: 'processed_at',
      shippingContainerId,
    })
      .then(() => handleSuccess({ description: 'Shipping container status has been changed to "processed" successfully.' }))
      .catch((error) => handleFailure(error, { description: `Shipping container status editing has failed. ${error}` }))
  }

  const handleSendBack = () => {
    setShippingContainerStatus({
      action: 'send_back',
      backendTimeFieldName: 'sent_back_at',
      shippingContainerId,
    })
      .then(() => handleSendBackSuccess({ description: 'Shipping container status has been changed to "sent back" successfully.' }))
      .catch((error) => handleFailure(error, { description: `Shipping container status editing has failed. ${error}` }))
  }

  const handleMediaRemove = ({ name, url }) => (
    new Promise((resolve) => {
      confirm({
        onOk: () => {
          if (url) {
            deleteShippingContainerMedia({ name, shippingContainerId })
              .then((result) => {
                handleSuccess({ description: 'Media file has been removed successfully.' })

                resolve(true)
              })
              .catch((error) => {
                handleFailure(error, { description: `Media file removal has failed. ${error}` })
                reload()

                resolve(false)
              })
          } else {
            handleSuccess({ description: 'Media file has been removed successfully.' })

            resolve(true)
          }
        },
        title: 'Are you sure you want to delete this media file?',
      })
    })
  )

  const handleSuccess = (messageOptions = {}, url = null) => {
    if (url) {
      navigate('/shipping-containers/')
    } else {
      reload()
    }

    notification.open({
      description: 'Media file has been created successfully.',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleSendBackSuccess = (messageOptions = {}) => {
    handleSuccess(messageOptions, '/shipping-containers/')
  }

  const handleFailure = (error, messageOptions = {}) => {
    reload()
    console.log(error)

    notification.open({
      description: `Media file creation has failed. ${error}`,
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const mediaFilesWithUId = data.media.map(media => ({
    ...media,
    uid: media.name,
  }))

  const isUnallocated = data.deposit_allocation === 'unallocated'
  const isCanceled = data.data_processing === 'canceled'
  const isRawData = data.data_processing === 'raw_data'
  const isProcessed = data.status === 'processed'
  const hasArrived = data.status === 'arrived'

  const isCanceledBtnDisabled = isCanceled || !(isRawData || isUnallocated)
  const isSendBackBtnDisabled = (isCanceled || !isProcessed)
  const isCompleteProcessingBtnDisabled = (isCanceled || !hasArrived)

  return (
    <div>
      <Title>
        Shipping Container Details
      </Title>

      <Row>
        <Col
          span={24}
          style={{ marginBottom: '2rem', textAlign: 'right' }}
        >
          <Can
            requiredPermission="update_status:shipping_containers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                disabled={isCanceledBtnDisabled}
                okText="Yes"
                onConfirm={() => handleCancelProcessing()}
                title="Are you sure you want to change the shipping container's status to 'canceled'?"
              >
                <Button
                  disabled={isCanceledBtnDisabled}
                  name="cancelShippingContainerSelenium"
                  style={{ marginRight: '30px' }}
                  type="primary"
                >
                  Cancel Shipping Container
                </Button>
              </Popconfirm>
            )}
          />
          <Can
            requiredPermission="update_status:shipping_containers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                disabled={isCompleteProcessingBtnDisabled}
                okText="Yes"
                onConfirm={() => handleCompleteProcessing()}
                title="Are you sure you want to change the shipping container's status to 'processed'?"
              >
                <Button
                  disabled={isCompleteProcessingBtnDisabled}
                  name="completeProcessingSelenium"
                  style={{ marginRight: '30px' }}
                  type="primary"
                >
                  Complete processing
                </Button>
              </Popconfirm>
            )}
          />

          <Can
            requiredPermission="update_status:shipping_containers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                disabled={isSendBackBtnDisabled}
                okText="Yes"
                onConfirm={() => handleSendBack()}
                title="Are you sure you want to change the shipping container's status to 'sent back'?"
              >
                <Button
                  disabled={isSendBackBtnDisabled}
                  name="sendBackSelenium"
                  type="primary"
                >
                  Send back to retailer
                </Button>
              </Popconfirm>
            )}
          />
        </Col>
      </Row>
      <Descriptions
        bordered
        className="shippingContainerDetailsTableSelenium"
        size="middle"
      >
        <Descriptions.Item label="ID" span={3}>
          {data.id}
        </Descriptions.Item>

        <Descriptions.Item label="Identity code" span={3}>
          {data.identity_code}
        </Descriptions.Item>

        <Descriptions.Item label="Seal code" span={3}>
          {data.seal_code}
        </Descriptions.Item>

        <Descriptions.Item label="Partner code" span={3}>
          {data.partner_code}
        </Descriptions.Item>

        <Descriptions.Item label="Status">
          {data.status}
        </Descriptions.Item>

        <Descriptions.Item label="Condition">
          {data.condition}
        </Descriptions.Item>

        <Descriptions.Item label="Quantity of packages">
          {data.quantity_of_packages}
        </Descriptions.Item>

        <Descriptions.Item label="Data processing" span={3}>
          {data.data_processing}
        </Descriptions.Item>

        <Descriptions.Item label="Deposit allocation" span={3}>
          {data.deposit_allocation}
        </Descriptions.Item>

        <Descriptions.Item label="Verification" span={3}>
          {data.verification}
        </Descriptions.Item>

        <Descriptions.Item label="Country" span={3}>
          {data.country}
        </Descriptions.Item>

        <Descriptions.Item label="Store" span={3}>
          {data.store ? `${data.store.name} - ${data.store.address}, ${data.store.zip}`: ''}
        </Descriptions.Item>

        <Descriptions.Item label="Arrived at" span={3}>
          {localizedDate(data.arrived_at)}
        </Descriptions.Item>

        <Descriptions.Item label="Processed at" span={3}>
          {localizedDate(data.processed_at)}
        </Descriptions.Item>

        <Descriptions.Item label="Sent back at" span={3}>
          {localizedDate(data.sent_back_at)}
        </Descriptions.Item>

        <Descriptions.Item label="Comment" span={3}>
          <Paragraph style={{ whiteSpace: 'pre-line' }}>
            {data.comment}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label="Images" span={3}>
          <Can
            no={() => (
              <Upload
                defaultFileList={mediaFilesWithUId}
                disabled={true}
              >
                <Button name="disabledUploadImageSelenium">
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
            requiredPermission="create:media"
            yes={() => (
              <Upload
                customRequest={handleMediaUpload}
                defaultFileList={mediaFilesWithUId}
                disabled={isCanceledBtnDisabled}
                onRemove={handleMediaRemove}
              >
                <Button
                  disabled={isCanceledBtnDisabled}
                  name="uploadImageSelenium"
                >
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
          />
        </Descriptions.Item>
      </Descriptions>

      <PackagesTable
        barcodes={barcodes[data.country] || []}
        className="packagesTableSelenium"
        isCanceledContainer={isCanceledBtnDisabled}
        shippingContainerId={shippingContainerId}
        shippingContainerStatus={data.status}
      />

      <Button
        name="showChangelog"
        onClick={() => setChangelogModalVisibility(true)}
        style={{ marginTop: '16px' }}
      >
        Show changelog
      </Button>

      {isChangelogModalVisible && (
        <ChangelogModal
          setVisibility={setChangelogModalVisibility}
          shippingContainerId={shippingContainerId}
        />
      )}
    </div>
  )
}

ShippingContainerDetailsPage.propTypes = {
  barcodes: PropTypes.object,
  ...routePropTypes,
}

export default ShippingContainerDetailsPage
