import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'
import {
  Button,
  Col,
  Empty,
  Popconfirm,
  Modal,
  notification,
  Row,
  Spin,
  Upload,
  Table,
  Typography,
} from 'antd'
import { Can, ErrorMessage, Icon } from '../../../_shared/components'
import { createPackage, createPackageMedia, deletePackage, deletePackageMedia, getShippingContainerPackages, updatePackage} from './api'
import PackagesModal from './PackagesModal'
import './PackagesTable.css'

const { Title, Paragraph } = Typography
const { confirm } = Modal

const PackagesTable = ({
  barcodes,
  isCanceledContainer = false,
  shippingContainerId,
  shippingContainerStatus,
}) => {
  const [isPackagesModalVisible, setPackagesModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const { data, error, isLoading, reload } = useAsync({
    promiseFn: getShippingContainerPackages,
    shippingContainerId,
  })

  if (isLoading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  const handleDeletePackage = (recordId) => {
    deletePackage({ recordId, shippingContainerId})
      .then(() => handleSuccess({ description: 'Package is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Package removal is failed.' }))
  }

  const handleSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'CREATE':
      return createPackage({
        fields: payload,
        shippingContainerId,
      })
        .then((response) => handleSuccess({ description: `Package "${response.upc}" is added successfully` }))
        .catch((error) => handleFailure(error, { description: 'Shipping container creation is failed.' }))

    case 'UPDATE':
      return updatePackage({ fields: payload, shippingContainerId })
        .then(handleSuccess)
        .catch(handleFailure)

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = (selectedPackage) => {
    setSelectedPackage(selectedPackage)
    setErrorMessage(null)
    setPackagesModalVisibility(true)
    setModalLoading(false)
  }

  const isNotArrived = shippingContainerStatus !== 'arrived'

  const tableColumns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'upcSelenium',
      dataIndex: 'upc',
      key: 'upc',
      title: 'Product Barcode',
    },
    {
      className: 'missingLabelSelenium',
      dataIndex: 'missing_label',
      key: 'missing_label',
      render: (_, record) => record.missing_label ? 'Yes' : 'No',
      title: 'Missing label',
    },
    {
      className: 'quantitySelenium',
      dataIndex: 'quantity',
      key: 'quantity',
      title: 'Quantity',
    },
    {
      className: 'conditionSelenium',
      dataIndex: 'condition',
      key: 'condition',
      title: 'Condition',
    },
    {
      className: 'verificationSelenium',
      dataIndex: 'verification',
      key: 'verification',
      title: 'Verification',
    },
    {
      className: 'skuSelenium',
      dataIndex: 'sku',
      key: 'sku',
      title: 'SKU',
    },
    {
      className: 'commentSelenium',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => (
        <Paragraph style={{ marginBottom: '0', whiteSpace: 'pre-line' }}>
          {record.comment}
        </Paragraph>
      ),
      title: 'Comment',
    },
    {
      className: 'mediaSelenium',
      dataIndex: 'media',
      key: 'media',
      render: (_, record) => {
        const mediaFilesWithUId = record.media.map(media => ({
          ...media,
          uid: media.name,
        }))

        return (
          <Can
            no={() => (
              <Upload
                defaultFileList={mediaFilesWithUId}
                disabled={true}
              >
                <Button>
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
            requiredPermission="create:media"
            yes={() => (
              <Upload
                customRequest={(media) => handleMediaUpload(media, record.id)}
                defaultFileList={mediaFilesWithUId}
                disabled={isCanceledContainer}
                onRemove={({ name }) => handleMediaRemove(name, record.id)}
              >
                <Button
                  disabled={isCanceledContainer}
                  name="uploadPackageImageBtnSelenium"
                >
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            )}
          />
        )
      },
      title: 'Media',
    },
    {
      className: 'statusSelenium',
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
    },
    {
      className: 'operationsSelenium',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:shipping_containers"
            yes={() => (
              <Button
                className="action-list-button"
                disabled={isNotArrived || isCanceledContainer}
                name="updatePackageSelenium"
                onClick={() => handleOpenModal(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />

          <Can
            requiredPermission={isNotArrived ? 'delete_unknown:packages' : 'delete:packages'}
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={(record.verification !== 'unknown' && isNotArrived) || (isCanceledContainer)}
                okText="Yes"
                onConfirm={() => handleDeletePackage(record.id)}
                title="Are you sure you want to delete this package?"
              >
                <Button
                  danger
                  disabled={(record.verification !== 'unknown' && isNotArrived) || (isCanceledContainer)}
                  ghost
                  name="deletePackageSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
    },
  ]

  const handleMediaUpload = (media, packageId) => {
    createPackageMedia({ media, packageId, shippingContainerId })
      .then(() => handleSuccess({ description: 'Media file is created successfully.' }))
      .catch((error) => handleFailure(error, { description: `Media file creation is failed. ${error}` }))
  }

  const handleMediaRemove = (name, packageId) => (
    new Promise((resolve) => {
      confirm({
        onOk: () => {
          deletePackageMedia({ name, packageId, shippingContainerId })
            .then(() => {
              handleSuccess({ description: 'Media file is removed successfully.' })

              resolve(true)
            })
            .catch((error) => {
              handleFailure(error, { description: 'Media file removal is failed.' })

              resolve(false)
            })
        },
        title: 'Are you sure you want to delete this media file?',
      })
    })
  )

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setPackagesModalVisibility(false)
    setErrorMessage(null)

    notification.open({
      description: 'Package is updated successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    reload()
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Package update is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  return (
    <div>
      <Row name="packages" style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Row
            align="middle"
            justify="space-between"
            style={{ margin: '20px 0 10px' }}
            type="flex"
          >
            <Col>
              <Title level={2}>Packages</Title>
            </Col>

            <Col>
              <Can
                disabled
                requiredPermission="create:packages"
                yes={() => (
                  <Button
                    disabled={isNotArrived || isCanceledContainer}
                    name="createPackageSelenium"
                    onClick={() => handleOpenModal(null)}
                    type="primary"
                  >
                    Add
                  </Button>
                )}
              />
            </Col>
          </Row>

          <Table
            bordered
            className="packagesTableSelenium"
            columns={tableColumns}
            dataSource={data || []}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No packages" /> }}
            pagination={false}
            rowKey="id"
            tableLayout="auto"
          />
        </Col>
      </Row>

      {isPackagesModalVisible && (
        <PackagesModal
          barcodes={barcodes}
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleSubmit}
          selectedPackage={selectedPackage}
          setModalLoading={setModalLoading}
          setVisibility={setPackagesModalVisibility}
        />
      )}
    </div>
  )
}

PackagesTable.propTypes = {
  barcodes: PropTypes.object,
  isCanceledContainer: PropTypes.bool,
  shippingContainerId: PropTypes.string.isRequired,
  shippingContainerStatus: PropTypes.string.isRequired,
}

export default PackagesTable
