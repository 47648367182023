import React from 'react'
import App from './App'
import { useAsync } from 'react-async'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AuthenticationLayer, ErrorMessage } from './_shared/components'
import { Auth0Provider } from './auth0'
import { getConfigs } from './api'

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const Root = () => {
  const { data, error, isLoading } = useAsync({
    promiseFn: getConfigs,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />
  if (isLoading) return null

  const router = createBrowserRouter([
    {
      element: (
        <AuthenticationLayer>
          <App barcodes={data.barcodes} retailers={data.retailers} />
        </AuthenticationLayer>
      ),
      path: '/*',
    },
  ])

  return (
    <Auth0Provider
      authorizationParams={{
        audience: data?.audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={data?.clientId}
      domain={data?.domain}
      onRedirectCallback={onRedirectCallback}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  )
}

export default Root
